<template>
  <v-card height="80%" :class="$vuetify.breakpoint.mdAndDown ? 'profile-detail-mobile' : 'profile-detail'" elevation="0">
    <WatchTutorialVideo style="margin: 18px 32px 20px" :label="$t(`label.howToRegisterAffiliate`)" video-url="https://krikyabangladesh.wistia.com/medias/45lhcqqcfq"/>
    <v-card class="profile-detail-card">
      <v-card-title v-if="step == 0 || step == 1">
        <v-row no-gutters justify="center">
          <v-col class="text-center" cols="7">
            <label class="detail-label text-capitalize">{{ $t(`label.agentRegister`) }}</label>
          </v-col>
        </v-row>
      </v-card-title>
      <v-window v-model="step" :touchless="true">
        <v-window-item :value="0">
          <v-form ref="bankAccountForm1" class="mt-4">
            <v-row no-gutters align="center" class="px-8" justify="center">
              <v-col lg="8" md="6">
                <app-form-field
                    :class="'mt-2'"
                    :enabledLabel="true"
                    :label="$t(`field.agentId`)"
                    :placeholder="$t(`fieldHint.fillUpHere`)"
                    v-model.trim="agentObj.agent_id"
                    :errorMsg="agentIdErrorMessage"
                    :blur="checkAgentId"
                    :rules="validator.usernameRules()"
                ></app-form-field>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="px-8" justify="center">
              <v-col lg="8" md="6">
                <app-form-field
                    :enabledLabel="true"
                    type="password"
                    :label="$t(`field.password`)"
                    :placeholder="$t(`fieldHint.fillUpHere`)"
                    v-model.trim="agentObj.password"
                    :rules="validator.passwordRules()"
                ></app-form-field>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="px-8" justify="center">
              <v-col lg="8" md="6">
                <app-form-field
                    :enabledLabel="true"
                    type="password"
                    :label="$t(`field.confirmPassword`)"
                    :placeholder="$t(`fieldHint.fillUpHere`)"
                    v-model.trim="confirm_password"
                    :rules="validator.confirmPasswordRules(agentObj.password, confirm_password)"
                ></app-form-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-8" justify="center">
              <v-col lg="8" md="6">
                <app-button :class="$vuetify.breakpoint.mdAndDown ? 'agent-detail-mobile dialog-button' : 'agent-detail dialog-button'" customClass="dialog-button-color" :title="$t(`button.next`)" :action="this.nextPage"></app-button>
              </v-col>
            </v-row>
          </v-form>
        </v-window-item>
        <v-window-item :value="1">
          <v-form ref="bankAccountForm2" class="mt-4">
            <v-row no-gutters align="center" class="px-8" justify="center">
              <v-col lg="8" md="6">
                <app-form-field
                    :enabledLabel="true"
                    :label="$t(`field.agentRealName`)"
                    :placeholder="$t(`fieldHint.fillUpHere`)"
                    v-model.trim="agentObj.real_name"
                    :rules="validator.realnameRules()"
                ></app-form-field>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="px-8" justify="center">
              <v-col lg="8" md="6">
                <app-form-field
                    :enabledLabel="true"
                    :label="$t(`field.nid`)"
                    :placeholder="$t(`fieldHint.fillUpHere`)"
                    v-model.trim="agentObj.nid_number"
                    :rules="validator.nidNumberRules(10,13)"
                ></app-form-field>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="px-8" justify="center">
              <v-col lg="8" md="6">
                <agent-app-form-field
                    type="datetime"
                    :enabledLabel="true"
                    :maxDate="dateOfBirthMaxDate"
                    :label="$t(`field.dateOfBirth`)"
                    :placeholder="$t(`fieldHint.fillUpHere`)"
                    v-model="agentObj.date_of_birth"
                ></agent-app-form-field>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="px-8" justify="center">
              <v-col lg="8" md="6">
                <app-form-field
                    :enabledLabel="true"
                    :isOptional="true"
                    :label="$t(`field.email`)"
                    :placeholder="$t(`fieldHint.fillUpHere`)"
                    v-model.trim="agentObj.email"
                    :rules="validator.emailRules()"
                ></app-form-field>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="px-8" justify="center">
              <v-col lg="8" md="6">
                <app-form-field
                    :enabledLabel="true"
                    :label="$t(`field.contactNumber`)"
                    :placeholder="$t(`fieldHint.fillUpHere`)"
                    v-model.trim="agentObj.mobile"
                    :prefix="mobilePrefix"
                    :rules="validator.mobileNumberRules('bd')"
                ></app-form-field>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="px-8" justify="center">
              <v-col lg="8" md="6">
                <app-form-field
                  :enabledLabel="true"
                  type="select"
                  :label="$t(`field.socialType`)"
                  :placeholder="$t(`fieldHint.fillUpHere`)"
                  v-model.trim="agentObj.social_contact_type"
                  :rules="validator.dropDownListRules()"
                  :items="socialTypeList"
                  :isOptional="true"
                  :displayText="this.socialContactTypeDdlText"
                ></app-form-field>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="px-8" justify="center" v-if="agentObj.social_contact_type == 1">
              <v-col lg="8" md="6">
                <app-form-field
                  :enabledLabel="true"
                  :label="'Social Contact (Telegram ID)'"
                  v-model.trim="agentObj.social_contact_value"
                  :rules="validator.requiredRules()"
                ></app-form-field>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="px-8" justify="center" v-if="agentObj.social_contact_type == 2">
              <v-col lg="8" md="6">
                <app-form-field
                  :enabledLabel="true"
                  :label="'Social Contact (IMO Number)'"
                  v-model.trim="agentObj.social_contact_value"
                  :prefix="mobilePrefix"
                  :rules="validator.mobileNumberRules('bd')"
                ></app-form-field>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="px-8" justify="center" v-if="agentObj.social_contact_type == 3">
              <v-col lg="8" md="6">
                <app-form-field
                  :enabledLabel="true"
                  :label="'Social Contact (Whatsapp Number)'"
                  v-model.trim="agentObj.social_contact_value"
                  :prefix="mobilePrefix"
                  :rules="validator.mobileNumberRules('bd')"
                ></app-form-field>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="px-8" justify="center">
              <v-col lg="8" md="6">
                <app-form-field
                    :enabledLabel="true"
                    type="select"
                    :label="$t(`field.commissionPayout`)"
                    :placeholder="$t(`fieldHint.fillUpHere`)"
                    v-model.trim="agentObj.commission_payout"
                    :rules="validator.dropDownListRules()"
                    :items="payout_method"
                    :displayText="this.payoutMethodDdlText"
                ></app-form-field>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" class="px-8" justify="center">
              <v-col lg="8" md="6">
                <app-form-field
                    :enabledLabel="true"
                    :label="$t(`field.walletNumber`)"
                    :placeholder="$t(`fieldHint.fillUpHere`)"
                    v-model.trim="agentObj.wallet_mobile"
                    :prefix="mobilePrefix"
                    :errorMsg="agentMobileErrorMessage"
                    :blur="checkAgentMobile"
                    :rules="validator.mobileNumberRules('bd')"
                ></app-form-field>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-8" justify="center">
              <v-col lg="8" md="6">
                <v-checkbox :label="$t(`message.applyAgentTermAndCondition`)" v-model="checkboxValue" class="mt-0"></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-8" justify="center">
              <v-col lg="8" md="6">
                <app-button :class="$vuetify.breakpoint.mdAndDown ? 'agent-detail-mobile dialog-button' : 'agent-detail dialog-button'" customClass="dialog-button-color" :title="$t(`button.back`)" :action="this.backPage"></app-button>
              </v-col>
            </v-row>
            <v-row no-gutters class="px-8" justify="center">
              <v-col lg="8" md="6">
                <app-button
                    :class="$vuetify.breakpoint.mdAndDown ? 'agent-detail-mobile dialog-button primary' : 'agent-detail dialog-button primary'"
                    :disabled="checkboxValue == false"
                    :title="$t(`button.joinNow`)"
                    :action="this.applyAgent"
                    custom-class="text-uppercase"
                ></app-button>
              </v-col>
            </v-row>
          </v-form>
        </v-window-item>
        <v-window-item :value="2" v-if="applyAgentSuccess">
          <v-col class="text-center mt-3 font-weight-bold" style="font-size: larger">
            <v-icon x-large color="green">check_circle</v-icon>
          </v-col>
          <v-col class="text-center mt-0 pt-0 font-weight-bold" style="font-size: xx-large">
            <label>{{ $t('field.registerSuccessful') }}</label>
          </v-col>
          <v-col class="text-center mt-0 font-weight-bold" style="font-size: large">
            <label>{{ $t('message.applyAgentReminderMessage') }}</label>
          </v-col>
          <v-col class="text-center mt-0 mb-2">
            <label>{{ $t('message.applyAgentDialogMessage1') }}</label>
          </v-col>
          <v-col class="text-center mt-0 mb-2 pt-0">
            <label>{{ $t('message.applyAgentDialogMessage4') }}</label>
          </v-col>
          <v-col class="text-center mt-0 mb-0 pt-0">
            <label>{{ $t('label.agentBackoffice') }}</label>
          </v-col>
          <v-col class="text-center mt-0 mb-0 pt-0">
            <a style="color: blue" :href="agentBackofficeUrl">{{ agentBackofficeUrl }}</a>
          </v-col>
          <v-col class="text-center mt-0 mb-0 pt-0">
            <label>{{ $t('label.applyAgentContactUs') }}</label>
          </v-col>
          <v-col class="text-center mt-0 mb-2 pt-0">
            <a style="color: blue" :href="agentWhatsappUrl">{{ agentWhatsappUrl }}</a>
          </v-col>
        </v-window-item>
        <v-window-item :value="2" v-else>
          <v-col class="text-center mt-3 font-weight-bold" style="font-size: larger">
            <v-icon x-large color="red">cancel</v-icon>
          </v-col>
          <v-col class="text-center mt-0 pt-0 font-weight-bold" style="font-size: xx-large">
            <label>{{ $t('field.registerFailed') }}</label>
          </v-col>
          <v-col class="text-center mt-0 pt-0 font-weight-bold" style="font-size: large">
            <label>{{ $t('message.applyAgentReminderMessage') }}</label>
          </v-col>
          <v-col class="text-center mt-0 mb-0">
            <label>{{ $t('message.applyAgentDialogMessage1') }}</label>
          </v-col>
          <v-col class="text-center mt-0 mb-2 pt-0">
            <label>{{ $t('message.applyAgentDialogMessage2') }}</label>
          </v-col>
        </v-window-item>
      </v-window>
    </v-card>
  </v-card>
</template>

<script>
import { locale, errorCodeHelper, formValidator, ddlHandler, uiHelper } from "@/util";
import find from 'lodash/find'
import { SHARED } from '@/constants/constants'
import { ROUTE_NAME } from '@/constants/route.constants'
import {
  AFFILIATE_APPLY_AGENT,
  AFFILIATE_RESET_APPLY_AGENT_STORE,
  AFFILIATE_APPLY_AGENT_DETAIL,
  AFFILIATE_CHECK_ID,
  AFFILIATE_RESET_CHECK_ID_STORE,
  AFFILIATE_CHECK_MOBILE,
  AFFILIATE_RESET_CHECK_MOBILE_STORE, AFFILIATE_CONFIG_URL
} from '@/store/affiliate.module'
import WatchTutorialVideo from "@/components/WatchTutorialVideo.vue";

export default {
  components: {WatchTutorialVideo},
  beforeRouteLeave(to, from, next) {
    if (to.name != ROUTE_NAME.APPLY_AGENT) {
      this.resetApplyAgentStore()
    }
    next()
  },
  data: () => ({
    validator: formValidator,
    agentObj: {
      agent_id: '',
      password: '',
      real_name: '',
      email: '',
      mobile: '',
      wallet_mobile: '',
      commission_payout: '',
      nid_number: '',
      date_of_birth: '',
      social_contact_type: '',
      social_contact_value: ''
    },
    dateOfBirthMaxDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString(),
    agentIdErrorMessage: undefined,
    agentMobileErrorMessage: undefined,
    confirm_password: '',
    checkboxValue: '',
    pageDialogShow: false,
    pageDialog: {
      title: '',
      closePageDialog: () => {},
      messageTitle: '',
      message: [],
      button: []
    },
    mobileNumberPrefixList: [
      {
        text: '+91',
        value: 'INR'
      },
      {
        text: '+880',
        value: 'BDT'
      }
    ],
    socialTypeList: ddlHandler.getSocialContactType(),
    payout_method: ddlHandler.getPayoutMethod(),
    step: 0
  }),
  created() {
    //this.applyAgentDetail()
    this.metaTag()
    this.getConfigUrl()
  },
  computed: {
    applyAgentComplete() {
      return this.$store.state.affiliate.applyAgent.complete
    },
    applyAgentSuccess() {
      return this.$store.state.affiliate.applyAgent.success
    },
    mobilePrefix() {
      let currency = SHARED.DEFAULT_CURRENCY
      let prefix = find(this.mobileNumberPrefixList, function(p) {
        return p.value == currency
      })
      if (prefix) return prefix.text
      else return ''
    },
    checkAgentIdResult() {
      return this.$store.state.affiliate.checkAgentIdResult.completed
    },
    checkAgentMobileResult() {
      return this.$store.state.affiliate.checkAgentMobileResult.completed
    },
    /*agentDetail() {
      return this.$store.state.affiliate.applyAgentDetail.data
    }*/
    agentBackofficeUrl() {
      return this.$store.state.affiliate.agentConfigUrl.backofficeUrl
    },
    agentWhatsappUrl() {
      return this.$store.state.affiliate.agentConfigUrl.whatsappUrl
    }
  },
  watch: {
    /*applyAgentComplete() {
      if (this.$store.state.affiliate.applyAgent.complete) {
        this.applyAgentCompleteDialog(this.$store.state.affiliate.applyAgent)
      }
    },*/
    checkAgentIdResult() {
      if (this.$store.state.affiliate.checkAgentIdResult.completed) {
        if (this.$store.state.affiliate.checkAgentIdResult.success) {
          this.agentIdErrorMessage = undefined
        } else {
          if (this.$store.state.affiliate.checkAgentIdResult.code == -1) {
            this.agentIdErrorMessage = locale.getMessage(`fieldErrorMessage.agentIdUsed`)
          } else if (this.$store.state.affiliate.checkAgentIdResult.code == -2) {
            this.agentIdErrorMessage = locale.getMessage(`fieldErrorMessage.agentIdApplied`)
          }
        }
        this.$store.dispatch(`${AFFILIATE_RESET_CHECK_ID_STORE}`)
      }
    },
    checkAgentMobileResult() {
      if (this.$store.state.affiliate.checkAgentMobileResult.completed) {
        if (this.$store.state.affiliate.checkAgentMobileResult.success) {
          this.agentMobileErrorMessage = undefined
        } else {
          if (this.$store.state.affiliate.checkAgentMobileResult.code == -1) {
            this.agentMobileErrorMessage = locale.getMessage(`fieldErrorMessage.agentMobileUsed`)
          }
        }
        this.$store.dispatch(`${AFFILIATE_RESET_CHECK_MOBILE_STORE}`)
      }
    },
    applyAgentSuccess() {
      if (this.$store.state.affiliate.applyAgent.success) {
        this.step++
      }
      if (this.$store.state.affiliate.applyAgent.success && this.$store.state.affiliate.applyAgent.automation && this.step == 2) {
        setTimeout(() => {
          window.location.href = this.$store.state.affiliate.agentConfigUrl.backofficeUrl
        }, 20000)
      }
    }
    /*agentDetail() {
      if (this.$store.state.affiliate.applyAgentDetail.data != null) {
        this.checkboxValue = true
      }
    }*/
  },
  methods: {
    metaTag() {
      const currentUrl = window.location.href;
      uiHelper.setCanonical(currentUrl)
      const domainWithProtocol = currentUrl.split('/')[2];
      const domain = domainWithProtocol.replace(/(^\w+:|^)\/\//, '');
      const path = window.location.pathname;
      const cleanPath = path.replace(/\/(en-BD|bn-BD)/i, '');
      const Links = `https://${domain}${cleanPath}`;
      const Linksen = `https://${domain}/en-BD${cleanPath}`;
      const Linksbn = `https://${domain}/bn-BD${cleanPath}`;
      uiHelper.setHreflangLinks(Links)
      uiHelper.setHreflangLinksen(Linksen)
      uiHelper.setHreflangLinksbd(Linksbn)
    },
    resetApplyAgentStore() {
      this.$store.dispatch(`${AFFILIATE_RESET_APPLY_AGENT_STORE}`)
    },
    getConfigUrl() {
      this.$store.dispatch(`${AFFILIATE_CONFIG_URL}`)
    },
    nextPage() {
      if (this.$refs.bankAccountForm1.validate()) this.step++
    },
    backPage() {
      this.step--
    },
    applyAgent() {
      if (this.$refs.bankAccountForm2.validate()) {
        let agentObj = this.agentObj
        this.$store.dispatch(`${AFFILIATE_APPLY_AGENT}`, { agentObj })
      }
    },
    checkAgentId() {
      let agentObj = this.agentObj.agent_id
      this.$store.dispatch(`${AFFILIATE_CHECK_ID}`, { agentObj })
    },
    checkAgentMobile() {
      let agentObj = this.agentObj.mobile
      this.$store.dispatch(`${AFFILIATE_CHECK_MOBILE}`, { agentObj })
    },
    /*applyAgentDetail() {
      this.$store.dispatch(`${AFFILIATE_APPLY_AGENT_DETAIL}`)
    },*/
    payoutMethodDdlText(data) {
      let d
      d = locale.getMessage(`agentPayoutMethod.${data.value}`)
      return d
    },
    socialContactTypeDdlText(data) {
      let d
      d = locale.getMessage(`agentSocialContactType.${data.value}`)
      return d
    },
    initializePageDialogProperty() {
      let initPageDialog = {
        title: locale.getMessage(`label.system`),
        dialogXButton: this.closePageDialog,
        messageTitle: '',
        message: [],
        button: []
      }
      return initPageDialog
    },
    openPageDialog(dialog) {
      this.$eventHub.$emit('open-page-dialog', dialog)
    },
    closePageDialog() {
      this.$eventHub.$emit('close-page-dialog', true)
      if (this.applyAgentSuccess) {
        this.$router.push({
          name: ROUTE_NAME.HOME
        })
      }
      this.$store.dispatch(`${AFFILIATE_RESET_APPLY_AGENT_STORE}`)
    }
    /*applyAgentCompleteDialog(response) {
      let dialog = this.initializePageDialogProperty()
      dialog.title = locale.getMessage(`label.applyAgent`)

      if (response.code != 0 && !response.success) {
        dialog.message.push(response.error)

        dialog.button.push({
          title: locale.getMessage(`label.close`),
          action: () => {
            this.closePageDialog()
          }
        })
      } else {
        dialog.messageTitle = locale.getMessage(`message.applyAgentReminderMessage`)
        dialog.message.push(locale.getMessage(`message.applyAgentDialogMessage`))
        dialog.button.push({
          title: locale.getMessage(`label.ok`),
          action: () => {
            this.closePageDialog()
          }
        })
      }
      dialog.dialogXButton = null
      this.openPageDialog(dialog)
    }*/
  }
}
</script>

<style lang="scss">
.detail-label {
  color: #3a3a3a;
}

.profile-detail-mobile {
  position: relative;
  max-width: 100%;
}

.profile-detail {
  position: relative;
  margin: 0px auto 0px auto;
  max-width: 708px;
}

.agent-detail.dialog-button {
  margin-left: 0px;
  margin-bottom: 10px;
  color: whitesmoke;
  border-radius: 5px !important;
  width: 100%;
}

.agent-detail-mobile.dialog-button {
  margin-bottom: 10px;
  color: whitesmoke;
  border-radius: 5px !important;
}

.dialog-button-color {
  color: var(--v-text_reverse-base);
  background-color: var(--v-secondary-base) !important;
  text-transform: uppercase;
}
</style>
